export type PartnerDataType = {
  username: string;
  id: string;
  isActive: boolean;
  created_at: string;
};

const GET_PARTNER = 'GET_PARTNER';
const SET_PARTNER = 'SET_PARTNER';

export const ActionType = {
  GET_PARTNER: GET_PARTNER,
  SET_PARTNER: SET_PARTNER,
};

export type GetPartnerActionType = {
  type: typeof ActionType[typeof GET_PARTNER];
  data: null;
};

export type SetPartnerActionType = {
  type: typeof ActionType[typeof SET_PARTNER];
  data: PartnerDataType[]
};

export type ActionTypes = GetPartnerActionType | SetPartnerActionType;

export type ContextType = {
  data: PartnerDataType[];
  request: boolean;
};