import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'

export default ({ isLoading = false, isOpen, onClose, children, title, size = 'lg' }: { isLoading?: boolean, size?: string, title: string, children: React.ReactNode, isOpen: boolean, onClose: (result: boolean) => void }) => {
    const OverlayOne = () => (
        /*<ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />*/
        <ModalOverlay
          bg='none'
          backdropFilter='auto'
          backdropInvert='80%'
          backdropBlur='2px'
        />
    );

    return (
        <>
            <Modal size={size} isCentered isOpen={isOpen} onClose={() => onClose(false)}>
                <OverlayOne />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {children}
                    </ModalBody>
                    <ModalFooter>
                        <Button isLoading={isLoading} variant={'ghost'} onClick={() => onClose(true)}>Aceptar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}