import React, { createContext, ReactNode } from 'react';
import { initialState, reducer } from './Reducer';
import { useSessionReducer } from '../../../hooks/useSessionReducer';
import { ContextType } from './Types';

export const PartnerSelectorContext = createContext<{
  state: ContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});

export const usePartnerSelector = () => {
  const context = React.useContext(PartnerSelectorContext)
  if (context === undefined) {
    throw new Error('usePartnerSelector must be used within a PartnerSelectorProvider')
  }
  return context
};

export const PartnerSelectorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'PartnerSelectorProvider');

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <PartnerSelectorContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </PartnerSelectorContext.Provider>
  );
};
