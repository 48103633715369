import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'

export default ({ isOpen, onClose, text }: { text: string, isOpen: boolean, onClose: (result: boolean) => void }) => {
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />
        /*  <ModalOverlay
          bg='none'
          backdropFilter='auto'
          backdropInvert='80%'
          backdropBlur='2px'
        />*/
    );

    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={() => onClose(false)}>
                <OverlayOne />
                <ModalContent>
                    <ModalHeader>Atención!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{text}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mr-4' variant={'outline'} onClick={() => onClose(false)}>Cancelar</Button>
                        <Button variant={'ghost'} onClick={() => onClose(true)}>Aceptar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}