import { ContextType, ActionTypes, ActionType } from './Types';

//the initial state of the user
export const initialState: ContextType = {
  data: [],
  request: false
};


//the reducer function note the parameter type annotations
export const reducer = (state: ContextType, action: ActionTypes) => {
  switch (action.type) {
    case ActionType.GET_PARTNER:
      return {
        ...state,
        request: true
      };
    case ActionType.SET_PARTNER:
      return {
        ...state,
        data: action.data,
        request: false
      };
    default:
      return state;
  }
};