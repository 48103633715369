import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/alert";

enum Status {
  info = 'info',
  warning = 'warning',
  success = 'success',
  error = 'error',
  loading = 'loading',
};


const AlertHorizon = (props: {
  type?: Status;
  icon: boolean;
  title?: string;
  description?: string;
}) => {
  const { type, title, icon, description } = props;
  return (
    <Alert className={`w-max rounded-xl bg-amber-300 py-3 px-4 text-sm shadow-xl dark:text-white shadow-shadow-500 dark:!bg-amber-700 dark:shadow-none my-5`} status={type} variant="solid" justifyContent="center">
      {icon ? <AlertIcon boxSize={'40px'} mr={4} /> : null}
      {title ? <AlertTitle  className="text-lg" mr={4}>{title}</AlertTitle> : null}
      {description ? <AlertDescription className="text-xs">{description}</AlertDescription> : null}
    </Alert>
  );
};

export default AlertHorizon;
