import React from "react";
import { useAuth } from './../../views/auth/context/Provider';
import { usePartnerSelector, PartnerSelectorProvider } from './context/Provider';
import { ActionType } from './context/Types';
import { Select } from "@chakra-ui/react";
import fetchData from "helpers/fetchData";
import { actionTypes } from './../../views/auth/context/Reducer';

export const ADMIN_ROL_NAME = 'admin';

const PartnerSelector = () => {
    const { dispatch: authDispatch, state: { data: userData } } = useAuth();
    const { dispatch, state: { data: partnerSelectorData, request } } = usePartnerSelector();

    const isAdmin = React.useMemo(() => userData.rol === ADMIN_ROL_NAME, [userData]);
    const drivewealthUserID = React.useMemo(() => userData.drivewealthUserID, [userData]);

    React.useEffect(() => {
        if (!isAdmin) {
            return;
        }

        fetchData(`drivewealth-users`)
            .then((data: any) => {
                dispatch({
                    type: ActionType.SET_PARTNER,
                    data: data
                });
            })
            .catch((error: any) => {
                console.error(new Error(error.statusText || error.message || error))
            });

    }, [isAdmin]);

    const patchUserDataById = async (id: string) => {
        try {
            await fetchData(`users/${userData.id}`, 'PATCH', { drivewealthUserID: id });
            const newUserData = { ...userData, drivewealthUserID: id };
            authDispatch({ type: actionTypes.SET_USER_DRIVEWEALTH_USER, data: newUserData });
            window.location.reload();
        } catch (error: any) {
            console.error(new Error(error.statusText || error.message || error))
        }
    }

    if (!isAdmin || request) {
        return null;
    }

    return (
        <Select background={'white'} cursor={'pointer'} value={drivewealthUserID} onChange={(e) => patchUserDataById(e.target?.selectedOptions?.[0]?.value)}>
            {partnerSelectorData.map((el: any) => <option value={el.id}>{el.name}</option>)}
        </Select>
    );
};

const PartnerSelectorProviderElement = () => {
    return <PartnerSelectorProvider> <PartnerSelector /></PartnerSelectorProvider>
};

export default PartnerSelectorProviderElement;
