import React from "react";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/avatar4.png";
import { useAuth } from './../../views/auth/context/Provider';
import { actionTypes } from './../../views/auth/context/Reducer';
import {
  MdOutlineDehaze
} from "react-icons/md";
import { Box, Select } from "@chakra-ui/react";
import PartnerSelector, { ADMIN_ROL_NAME } from './../partnerSelector';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { dispatch, state: { data } } = useAuth();
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const isAdmin = React.useMemo(() => data.rol === ADMIN_ROL_NAME, [data]);

  //TODO :any
  const logOut = () => dispatch({ type: actionTypes.UNSET_USER });

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Admin
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>
      <Box className="flex items-center gap-5 justify-center">
        {isAdmin ? <PartnerSelector /> : null}
        <div className="relative cursor-pointer mt-[3px] flex h-[61px] w-[55px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2">

          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <MdOutlineDehaze className="ml-1 h-6 w-6 " />
            }
            children={
              <div className="flex h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="mt-3 ml-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 {data.username}
                    </p>{" "}
                  </div>
                </div>
                <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="mt-3 ml-4 flex flex-col">
                  <button
                    onClick={() => {
                      if (darkmode) {
                        document.body.classList.remove("dark");
                        setDarkmode(false);
                      } else {
                        document.body.classList.add("dark");
                        setDarkmode(true);
                      }
                    }}
                    className="mt-3 text-sm font-medium text-left"
                  >
                    {darkmode ? (
                      <span><RiSunFill className="h-4 w-4 text-gray-600 dark:text-white float-left mr-2" /> Light </span>
                    ) : (
                      <span><RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white float-left mr-2" /> Dark </span>
                    )}
                  </button>
                  <Link
                    hidden={true}
                    to="/admin/profile"
                    className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Perfil
                  </Link>
                  <button
                    onClick={logOut}
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 text-left"
                  >
                    Salir
                  </button>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </Box>
    </nav>
  );
};

export default Navbar;
