import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from '@chakra-ui/react';
const LABEL: string = 'Volver';

export default function BackButton({ request, label = LABEL }: { label?: string, request: boolean }) {
    const navigate = useNavigate();

    return <Button
        onClick={() => navigate(-1)}
        className={`text-xl hover:cursor-pointer py-2 px-4 ${request
            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
            : "bg-lightPrimary text-brand-400 hover:text-brand-700 dark:bg-lightPrimary/40 dark:text-white dark:hover:text-lightPrimary dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-medium`}
    >
        {label}
    </Button>;
}