import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { AuthProvider } from './views/auth/context/Provider';
import { ChakraProvider } from '@chakra-ui/react'

const App = () => {

  return (<ChakraProvider>
    <AuthProvider>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </AuthProvider>
  </ChakraProvider>
  );
};

export default App;
