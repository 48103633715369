import { useState } from "react";
import Calendar, { OnChangeDateCallback, OnChangeDateRangeCallback } from "react-calendar";
import Card from "components/card";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";

const defaultDate: [Date, Date] = [new Date(), new Date()];

const MiniCalendar = ({
  maxDate = null,
  allowPartialRange = false,
  selectRange = false,
  onChange = () => { },
  value = defaultDate
}: {
  maxDate?: Date,
  allowPartialRange?: boolean,
  selectRange?: boolean,
  onChange?: OnChangeDateCallback | OnChangeDateRangeCallback,
  value?: [Date, Date]
}) => {

  return (
    <div>
      <Card extra="flex w-full h-full flex-col px-3 py-3">
        <Calendar
          selectRange={selectRange}
          allowPartialRange={allowPartialRange}
          maxDate={maxDate}
          onChange={onChange}
          value={value}
          goToRangeStartOnSelect={false}
          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
          nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
          view={"month"}
        />
      </Card>
    </div>
  );
};

export default MiniCalendar;
